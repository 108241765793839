<template>
  <div>
    <v-list two-line class="ma-0 pa-0" v-if="true">
      <v-list-item @click.prevent="loadIntro()">
        <v-list-item-avatar>
          <v-img src="/assets/landingPages/stained_glass_bunjil.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle
            class="font-weight-bold text-subtitle-2"
            v-html="'Acknowledgement of Country'"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="false"
            v-html="'Welcome'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <center v-if="!layers.length">
      <br />
      <div class="ml-4 heading right">... no items</div>
    </center>

    <v-btn
      v-if="!layers.length"
      depressed
      :color="appSettings.mediaButtonColor"
      :width="appSettings.drawButtonWidth"
      class="ma-2 white--text"
      @click="resetFilters()"
    >
      <v-icon left>mdi-cached</v-icon>

      Reset category filters
    </v-btn>

    <v-list class="ma-0 pa-0">
      <v-list-item-group
        class="ma-0 pa-0"
        active-class="error--text"
        v-model="openPopupSiteAct"
      >
        <template v-for="(site, index) in sites">
          <div
            v-bind:key="'heading_' + site.properties.index"
            v-if="
              layers.includes(site.properties.Category) &&
                (!sites[index - 1] ||
                  (sites[index - 1] &&
                    sites[index - 1].properties.Category !=
                      sites[index].properties.Category))
            "
          >
            <v-divider class="mt-3 mb-2"></v-divider>

            <div class="ml-4 heading" style="height:65px">
              <v-avatar class="profile" size="60" tile style="float:left">
                <img
                  :src="
                    `/icons/${
                      appSettings.categoryLookup[site.properties.Category].logo
                    }.png`
                  "
              /></v-avatar>

              <div
                style="height:60px;padding-left:62px;"
                class="ml-2"
                :style="{
                  'padding-top':
                    appSettings.categoryLookup[site.properties.Category].padding
                }"
              >
                {{
                  appSettings.categoryLookup[site.properties.Category].titleLong
                }}
              </div>
            </div>
            <v-divider style="opacity: .6;" class="mt-1"></v-divider>
          </div>

          <v-list-item
            v-if="layers.includes(site.properties.Category)"
            v-bind:key="site.properties.index"
            v-bind:class="[
              'siteKeyAct_' + site.properties.index,
              openPopupSite === site.properties.index ? 'currentlyOpen' : ''
            ]"
            @click.prevent="focusOnSite(site.properties.index)"
            v-on:dblclick="loadMoreInfo(site.properties.index)"
          >
            <v-list-item-avatar>
              <img
                :src="
                  `/icons/${
                    appSettings.categoryLookup[site.properties.Category].logo
                  }.png`
                "
              />

              <span
                v-if="false"
                class="white--text text-h7"
                v-show="appSettings.showSiteNumbers"
              >
                {{ site.properties.Site_Marker_Number }}
              </span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="site.properties.Point_Name"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../main";

export default {
  name: "SiteList",
  props: {
    isOpen: Boolean
  },
  computed: {
    ...mapState({
      openPopupSite: state => state.navigation.openPopupSite,
      markerList: state => state.markers.markerList,
      baseurllocation: state => state.navigation.baseurllocation,
      appSettings: state => state.global.appSettings,
      layers: state => state.filters.layers,
      defaultLayers: state => state.filters.defaultLayers
    }),
    sites() {
      return this.markerList.data.features;
    },
    origin() {
      return window.location.origin;
    }
  },
  data: () => ({
    cancelScroll: null,
    openPopupSiteAct: false,
    holdScroll: false,
    oldSlectedSite: false
  }),
  methods: {
    resetFilters() {
      this.$store.dispatch("controlsUpdateLayers", this.defaultLayers);
    },
    getColor(theme) {
      let output = "red";
      switch (theme) {
        case "Prospective Families":
          output = this.appSettings.styles.markerColours[3];
          break;
        case "Wayfinding":
          output = this.appSettings.styles.markerColours[1];
          break;
        case "History":
          output = this.appSettings.styles.markerColours[2];
          break;
      }
      return output;
    },
    loadIntro() {
      eventBus.$emit("loadIntroPopup");
    },
    loadMoreInfo(index) {
      if (this.oldSlectedSite === index) {
        this.$store.dispatch("navUpdateSidebarSite", index);
        this.$store.dispatch("navUpdateSidebarOpenToInfo");
      }
    },
    focusOnSite(index) {
      //console.log("index", { index });
      //this.$router.push({path: '/zzz'});

      this.holdScroll = true;
      //if(this.cancelScroll){this.cancelScroll();}
      setTimeout(() => {
        this.holdScroll = false;
        //this.cancelScroll();
      }, 500);

      try {
        if (this.sites[index]) {
          let coordinates;
          coordinates = this.sites[index].geometry.coordinates
            ? this.sites[index].geometry.coordinates
            : this.sites[index].properties.Longitude &&
              this.sites[index].properties.Latitude
            ? [
                this.sites[index].properties.Longitude,
                this.sites[index].properties.Latitude
              ]
            : false;

          if (coordinates) {
            this.$store.dispatch("navUpdateFocusSite", index);
            try {
              //close menu if on mobile/tablet

              if (window.outerWidth < 1263) {
                setTimeout(() => {
                  this.$store.dispatch("navUpdateCloseMenu", true);
                }, 400);
              }
            } catch (error) {
              //do nothing
            }
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    scrollToSite(newValue) {
      try {
        setTimeout(() => {
          if (!this.holdScroll) {
            let element = ".siteKeyAct_" + newValue;
            this.cancelScroll = this.$scrollTo(element);
          }
        }, 60);
      } catch (error) {
        //do nothing!
      }
    }
  },

  watch: {
    isOpen(newValue) {
      if (newValue === true) {
        this.scrollToSite(this.openPopupSiteAct);
      }
    },
    openPopupSite(newValue, oldValue) {
      setTimeout(() => {
        this.oldSlectedSite = newValue;
      }, 150);

      this.openPopupSiteAct = newValue;
      if ((!isNaN(newValue), newValue !== oldValue)) {
        if (!this.holdScroll) {
          this.scrollToSite(newValue);
        }
      }
    }
  },

  async mounted() {}
};
</script>
<style>
.currentlyOpen {
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #546b66 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}
</style>
