<template>
  <div
    style="width:0;height:0;
    overflow: visible;"
  >
    <div
      v-if="!$vuetify.breakpoint.xsOnly"
      style="
    right: 0;
    bottom:-50px;
    position: absolute;"
    >
      <v-row class="px-2 pb-2 ma-0" justify="space-between">
        <v-btn-toggle v-model="layers" multiple>
          <v-btn
            v-for="item in setup.bools"
            :key="item.key"
            :value="item.key"
            small
            :color="layers.includes(item.key) ? /*item.color*/ '#9ec0bd' : null"
          >
            <v-icon
              left
              v-if="!layers.includes(item.key)"
              :color="appSettings.categoryLookup[item.key].color"
              >mdi-checkbox-blank-outline
            </v-icon>
            <v-icon
              left
              v-if="layers.includes(item.key)"
              :color="appSettings.categoryLookup[item.key].color"
              >mdi-checkbox-intermediate
            </v-icon>
            <font
              :class="
                layers.includes(item.key)
                  ? 'warning--text'
                  : 'secondary--text  text--lighten-2 '
              "
            >
              {{ item.title }}</font
            >
          </v-btn>
        </v-btn-toggle>

        <v-tooltip
          v-model="show"
          left
          bottom
          nudge-left="120"
          nudge-top="2"
          id="main"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="on || !on"
              z-depth="0"
              depressed
              style="border: 1px solid #d8d8d8;"
              small
              class="ml-2 pa-0"
              xcolor="#9ec0bd"
              @click="show = !show"
            >
              <v-icon color="error">mdi-information-outline </v-icon>
            </v-btn>
          </template>
          <span>Select the categories you would like to see on the map.</span>
        </v-tooltip>
      </v-row>
    </div>

    <div
      v-if="$vuetify.breakpoint.xsOnly"
      class="mobileSet"
      style="
    right: 0;
    bottom:-60px;
    position: absolute;border-radius: 2px;
    "
    >
      <v-row class="px-0 pb-0 ma-0" justify="space-between" style="">
        <v-btn-toggle
          v-model="layers"
          multiple
          style="flex-direction: column; ;"
        >
          <v-btn
            v-for="item in setup.bools"
            :key="item.key"
            :value="item.key"
            x-small
            :color="layers.includes(item.key) ? /*item.color*/ '#9ec0bd' : null"
            style="font-size: 0.5em;border-radius: 0;justify-content: left;"
          >
            <v-icon
              x-small
              left
              v-if="!layers.includes(item.key)"
              :color="appSettings.categoryLookup[item.key].color"
              >mdi-checkbox-blank-outline
            </v-icon>
            <v-icon
              x-small
              left
              v-if="layers.includes(item.key)"
              :color="appSettings.categoryLookup[item.key].color"
              >mdi-checkbox-intermediate
            </v-icon>
            <font
              :class="
                layers.includes(item.key)
                  ? 'warning--text'
                  : 'secondary--text  text--lighten-2 '
              "
            >
              {{ item.title }}</font
            >
          </v-btn>
        </v-btn-toggle>

        <v-tooltip
          v-model="show"
          left
          bottom
          nudge-left="120"
          nudge-top="2"
          v-if="false"
        >
          <template v-slot:activator="{ on }">
            <div style="height:0;width:0">
              <div
                style="height:25px;width:25px;margin-left:-30px;margin-top: -3px;"
              >
                <v-btn
                  v-if="on || !on"
                  z-depth="0"
                  depressed
                  style="border: 1px solid #d8d8d8;"
                  class="ml-0 pa-0"
                  xcolor="#9ec0bd"
                  @click="show = !show"
                  x-small
                >
                  <v-icon x-small class="ml-0 pa-0" color="error"
                    >mdi-information-outline
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <span>Select the categories you would like to see on the map.</span>
        </v-tooltip>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SoundCloudPlayer",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      setup: state => state.filters.setup,
      colors: state => state.filters.colors
    }),

    layers: {
      get() {
        //return this.selectedClone;
        return this.$store.state.filters.layers;
      },
      set(value) {
        this.show = false;
        this.$store.dispatch("controlsUpdateLayers", value);
      }
    }
  },
  data: () => ({
    readOnlyButtonValues: [],
    show: false
  }),
  methods: {},
  async mounted() {},

  watch: {}
};
</script>

<style scoped></style>
