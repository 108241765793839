const createBlankImage = function({ width = 1, height = 1 }) {
  // this creates a blank image while the icons load.
  //var width = 1;

  var bytesPerPixel = 4;
  var blankImage = new Uint8Array(height * width * bytesPerPixel);
  for (var x = 0; x < height; x++) {
    for (var y = 0; y < width; y++) {
      var offset = (y * width + x) * bytesPerPixel;
      blankImage[offset + 0] = 1; // red
      blankImage[offset + 1] = 1; // green
      blankImage[offset + 2] = 1; // blue
      blankImage[offset + 3] = 0; // alpha
    }
  }
  return blankImage;
};

export default new (class {
  async loadImageAssets(map, imageList, scaleFactors, geoJson) {
    var genIcon = async function({
      map,
      width,
      height,
      imageId,
      file,
      scaleFactor
    }) {
      if ((height, width)) {
        //do nothing
      }
      const blankImage = createBlankImage({ width: 1, height: 1 });

      map.addImage(imageId, {
        width: 1,
        height: 1,
        data: blankImage
      });

      await map.loadImage(
        //`${window.location.origin}${baseurllocation}/icons/actPin/${imageId}.png`,
        `${window.location.origin}/${file}`,
        async (error, image) => {
          //console.log("image loaded", imageId, image);
          if (error) throw error;

          map.removeImage(imageId); //remove dummy image
          //add loaded image
          map.addImage(imageId, image, {
            pixelRatio: 1 / scaleFactor
          });
        }
      );
    };

    var validAssetsList = geoJson.data.features.reduce(function(map, obj) {
      if (obj.properties && obj.properties.name)
        map[obj.properties.name] = true;
      return map;
    }, {});

    const loadNumberIconsLoop = async () => {
      for (let index = 0; index < imageList.length; index++) {
        let { asset, file, height, width } = imageList[index];

        if (validAssetsList[asset])
          genIcon({
            map,
            width,
            height,
            imageId: "artwork_" + asset,
            file,
            scaleFactor: scaleFactors[asset] ? scaleFactors[asset] : 1
          });
      }
    };

    await loadNumberIconsLoop();
  }
})();
