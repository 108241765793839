<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="640">
      <template v-slot:activator="{ on, attrs }">
        <div class="">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            v-if="true"
            :color="appSettings.mediaButtonColor"
            :width="width"
            class="ma-2 white--text"
          >
            <v-icon left>mdi-camera</v-icon>
            {{ title }}
          </v-btn>
        </div>
      </template>

      <v-card>
        <v-card-title
          v-if="false"
          :style="{
            background: appSettings.menuBarColor
          }"
          :class="
            appSettings.headingTextColor + ' headline  hidden-sm-and-down'
          "
        >
          {{ youTubeTitle }}
        </v-card-title>

        <div
          class="streetViewDialog"
          ref="box"
          v-if="googleStreetIframe && url"
        >
          <iframe
            :src="url"
            width="100%"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row
            no-gutters
            class="contentWrapper  secondary--text "
            style=""
          ></v-row>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",
  props: {
    width: String,
    googleStreetIframe: String
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    }),

    url() {
      try {
        if (this.googleStreetIframe && this.googleStreetIframe.includes("||")) {
          return this.googleStreetIframe.split("||")[0].trim();
        } else {
          return this.googleStreetIframe;
        }
      } catch (error) {
        return null;
      }
    },
    title() {
      try {
        if (this.googleStreetIframe && this.googleStreetIframe.includes("||")) {
          return this.googleStreetIframe.split("||")[1].trim();
        } else {
          return "Photo Sphere";
        }
      } catch (error) {
        return "Photo Sphere";
      }
    }
  }
};
</script>

<style>
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
@media only screen and (max-width: 960px) {
  .contentWrapper {
    max-height: calc(100vh - 280px);
  }
}
</style>
