<template>
  <div class="my-0 ">
    <div class="soundcloudLinks">
      <v-btn
        depressed
        v-if="properties.audioDescription_soundCloudId && false"
        :color="appSettings.mediaButtonColor"
        :width="width"
        class="autoHeightButton ma-2 white--text"
        @click="
          setSound(
            properties.audioDescription_soundCloudId,
            properties.Point_Name
          )
        "
      >
        <v-icon left>{{ siteAudio__audioPlayIcon_01 }}</v-icon>
        <span :style="{ 'xmax-width': textWidth }">{{
          playSite_AudioTitle01
        }}</span>
      </v-btn>
    </div>

    <v-btn
      :title="
        properties.audioDescription_soundCloudTitle
          ? properties.audioDescription_soundCloudTitle
          : false
      "
      depressed
      v-if="properties.audioDescription_soundCloudId"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="my-1 mx-2 white--text oneLineEclipsText"
      @click="
        setSound(
          properties.audioDescription_soundCloudId,
          properties.Point_Name
        )
      "
    >
      <v-icon left>{{ siteAudio__audioPlayIcon_01 }}</v-icon>
      <span :style="{ 'max-width': textWidth }">{{
        playSite_AudioTitle01
      }}</span>
    </v-btn>
    <v-btn
      :title="
        properties.audioDescription_soundCloudTitle_02
          ? properties.audioDescription_soundCloudTitle_02
          : false
      "
      depressed
      v-if="properties.audioDescription_soundCloudId_02"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="my-1 mx-2  white--text oneLineEclipsText"
      @click="
        setSound(
          properties.audioDescription_soundCloudId_02,
          properties.Point_Name
        )
      "
    >
      <v-icon left>{{ siteAudio__audioPlayIcon_02 }}</v-icon>

      <span :style="{ 'max-width': textWidth }">{{
        playSite_AudioTitle02
      }}</span>
    </v-btn>

    <v-btn
      :title="
        properties.audioDescription_soundCloudTitle_03
          ? properties.audioDescription_soundCloudTitle_03
          : false
      "
      depressed
      v-if="properties.audioDescription_soundCloudId_03"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="my-1 mx-2  white--text oneLineEclipsText"
      @click="
        setSound(
          properties.audioDescription_soundCloudId_03,
          properties.Point_Name
        )
      "
    >
      <v-icon left>{{ siteAudio__audioPlayIcon_03 }}</v-icon>
      <span :style="{ 'max-width': textWidth }">{{
        playSite_AudioTitle03
      }}</span>
    </v-btn>
    <div
      v-if="!$vuetify.breakpoint.smAndDown && appSettings.autoOpenMoreInfo"
      style="height:3px"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayButton",
  components: {},
  data: () => ({
    userAgent: "",
    isIpad: false,
    ready: false,
    siteAudio__audioPlayIcon_01: "",
    siteAudio__audioPlayIcon_02: "",
    siteAudio__audioPlayIcon_03: "",
    playTitle: "",
    interval: false,
    playSite_AudioTitle01: "",
    playSite_AudioTitle02: "",
    playSite_AudioTitle03: ""
  }),

  props: {
    basicTitle: Boolean,
    isPopup: Boolean,
    getAudioDetails: Function,
    setSound: Function,
    width: String,
    appSettings: Object,
    properties: {
      required: true,
      type: Object
    }
  },
  computed: {
    textWidth() {
      try {
        return `${parseFloat(this.width.replace("px", "")) - 30}px`;
      } catch (e) {
        //do nothing;
        return "";
      }
    }
  },

  methods: {
    updatePlayTitle(type, name, id, soundCloudTitle, hasMulti) {
      let tempAudio = this.getAudioDetails(name, id);

      let tempIcon = "false";
      let title = "";
      if (tempAudio && !tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
        if (
          this.$browserDetect.isIOS ||
          this.$browserDetect.isChromeIOS ||
          this.$browserDetect.isSafari ||
          this.isIpad
        ) {
          title = "Load Audio";
          tempIcon = "mdi-volume-high";
        }
      } else if (!tempAudio.isPlaying && tempAudio.isLoadedThisTrack) {
        title = "Play Audio";
        tempIcon = "mdi-play-outline";
      } else if (tempAudio.isPlayingThisTrack) {
        title = "Pause Audio";
        tempIcon = "mdi-pause";
      } else {
        title = "Load Audio";
        tempIcon = "mdi-cassette";
      }

      this.playTitle = title;
      let playSite_AudioTitle01, playSite_AudioTitle02, playSite_AudioTitle03;

      if (hasMulti) {
        playSite_AudioTitle01 = title.replace(
          "Audio",
          soundCloudTitle ? soundCloudTitle : "Site Audio 1"
        );
      } else {
        playSite_AudioTitle01 = title.replace(
          "Audio",
          soundCloudTitle ? soundCloudTitle : "Site Audio"
        );
      }

      playSite_AudioTitle02 = title.replace(
        "Audio",
        soundCloudTitle ? soundCloudTitle : "Site Audio 2"
      );

      playSite_AudioTitle03 = title.replace(
        "Audio",
        soundCloudTitle ? soundCloudTitle : "Site Audio 3"
      );

      //this.playSite_AudioTitle01 = this.playTitle;
      //this.siteAudio__audioPlayIcon_01 = tempIcon;
      if (this.basicTitle) {
        if (type === "SiteAudio01") {
          this.playSite_AudioTitle01 = this.playTitle;
          this.siteAudio__audioPlayIcon_01 = tempIcon;
        } else if (type === "SiteAudio02") {
          this.playSite_AudioTitle02 = this.playTitle;
          this.siteAudio__audioPlayIcon_02 = tempIcon;
        } else {
          this.playSite_AudioTitle03 = this.playTitle;
          this.siteAudio__audioPlayIcon_03 = tempIcon;
        }
      } else if (!this.basicTitle) {
        if (type === "SiteAudio01") {
          this.playSite_AudioTitle01 = playSite_AudioTitle01;
          this.siteAudio__audioPlayIcon_01 = tempIcon;
        } else if (type === "SiteAudio02") {
          this.playSite_AudioTitle02 = playSite_AudioTitle02;
          this.siteAudio__audioPlayIcon_02 = tempIcon;
        } else {
          this.playSite_AudioTitle03 = playSite_AudioTitle03;
          this.siteAudio__audioPlayIcon_03 = tempIcon;
        }
      }
    }
  },

  async mounted() {
    var userAgent = window.navigator.userAgent;
    this.userAgent = userAgent;
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/Macintosh/i)
    ) {
      this.isIpad = true;
    } else {
      // Anything else
    }

    let _this = this;

    let hasMulti = false;
    if (
      _this.properties.audioDescription_soundCloudId_02 ||
      _this.properties.audioDescription_soundCloudId_03
    ) {
      hasMulti = true;
    }

    _this.updatePlayTitle(
      "SiteAudio01",
      _this.properties.Point_Name,
      _this.properties.audioDescription_soundCloudId,
      hasMulti
    );
    _this.updatePlayTitle(
      "SiteAudio02",
      _this.properties.Point_Name,
      _this.properties.audioDescription_soundCloudId_02,
      hasMulti
    );

    _this.updatePlayTitle(
      "SiteAudio03",
      _this.properties.Point_Name,
      _this.properties.audioDescription_soundCloudId_03,
      hasMulti
    );

    _this.interval = setInterval(function() {
      _this.updatePlayTitle(
        "SiteAudio01",
        _this.properties.Point_Name,
        _this.properties.audioDescription_soundCloudId,
        _this.properties.audioDescription_soundCloudTitle,
        hasMulti
      );
      _this.updatePlayTitle(
        "SiteAudio02",
        _this.properties.Point_Name,
        _this.properties.audioDescription_soundCloudId_02,
        _this.properties.audioDescription_soundCloudTitle_02,
        hasMulti
      );
      _this.updatePlayTitle(
        "SiteAudio03",
        _this.properties.Point_Name,
        _this.properties.audioDescription_soundCloudId_03,
        _this.properties.audioDescription_soundCloudTitle_03,
        hasMulti
      );
    }, 150);
  },
  async destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.v-btn.oneLineEclipsText span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
