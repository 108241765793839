const getters = {};
const actions = {};
const state = {
  appSettings: {
    markerIsPointer: false,
    showSiteNumbers: false,
    showQrCode: false,
    minZoom: { main: 14, secondary: 17, points: 16.5 },
    categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        titleLong: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        titleLong: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        titleLong: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },

    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 300,
    drawButtonWidth: "286px",
    mediaButtonColor: "#9ec0bd", //a6caa6
    appButtonColor: "#1f3531",
    dialogActionButtonColor: "#1f3531",

    menuBarColor: "#9ec0bd",
    audioPlayColor: "1f3531",
    drawWidthx: 300,
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerText: "#fcfbf1"
    }
  }
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations
};
