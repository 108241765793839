<template>
  <div class="mapWrapper">
    <mapbox
      :access-token="accessToken"
      :map-options="options"
      @map-load="loaded"
      @map-init="initialized"
      :nav-control="{
        show: true,
        position: 'top-left'
      }"
      :geolocate-control="{
        show: true,
        position: 'top-left',
        options: {
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        }
      }"
      :scale-control="{
        show: false,
        position: 'top-left'
      }"
      :fullscreen-control="{
        show: true,
        position: 'top-left'
      }"
    />
  </div>
</template>

<script>
//cluster ajsdflj https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/

import { mapState } from "vuex";

import Mapbox from "mapbox-gl-vue";
import _ from "lodash";
//import mapboxgl from "mapbox-gl";

import PopupStackedMenu from "./../info/PopupStackedMenu.vue";
import PopupContent from "./../info/PopupContent.vue";
import markerService from "./services/markerService.js";
import artworkAssetService from "./services/artworkAssetService.js";
export default {
  components: { Mapbox },
  name: "Map",
  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings,
      playingSiteName: state => state.media.playingSiteName,
      currentTrack: state => state.media.currentTrack,
      isPlaying: state => state.media.isPlaying,

      //markers:
      stackedMarkers: state => state.markers.stackedMarkers,
      markerList: state => state.markers.markerList,

      //sites:
      focusSite: state => state.navigation.focusSite,
      baseurllocation: state => state.navigation.baseurllocation,
      layers: state => state.filters.layers
    }),
    sites() {
      return this.markerList.data.features;
    }
  },

  props: {
    msg: String
  },
  data: () => ({
    lastMarkerSize: null,
    toolTipAct: null,
    useCluster: false,
    useCircles: true,
    popups: [],
    map: null,
    accessToken:
      //"pk.eyJ1IjoiYXNpbXMiLCJhIjoiMDhlX2hTUSJ9.Sqq7Mq0CPccTk_WLqKjb4A",
      //"pk.eyJ1IjoiY2FydGRlY28iLCJhIjoiMkRSMXQzUSJ9.74YN7Lx-RgcpjBPWjtGcog",

      "pk.eyJ1IjoibGlseWdpbGJ5IiwiYSI6ImNrdHd4Ym5lNTJvZ20yb251cXNraDJjcHcifQ.zGeYkQ3T9kDMsnUHORBXXQ",
    options: {
      container: "mapContainer",
      //style: "mapbox://styles/lilygilby/cl3fov318000514l6pgi4p240",//current but issue on ipad

      //style: "mapbox://styles/lilygilby/cl348k1du000215pl4tgmxakl",//old still borken

      //two layers
      //style: "mapbox://styles/lilygilby/cl3u7fvr1000214pkrjjns8sp",

      //zero layers
      style: "mapbox://styles/lilygilby/cl6bedx2o000014r006si7v0p",

      //one layer
      //style: "mapbox://styles/lilygilby/cl3u7lysz000114ph0bu3p15r",

      //style: "mapbox://styles/mapbox/streets-v11",
      center: [144.3395415, -38.1488939],
      //padding: { top: 10, bottom: 25, left: 15, right: 700 },
      maxZoom: 22,
      //xmaxBounds: [
      //[144.818781, -38.061],
      //[145.112861, -37.6425]
      //[143, -40],
      //[147, -36]
      //],
      pitch: 30, // pitch in degrees
      bearing: -81, // bearing in degrees
      zoom: 18 //17.2
    }
  }),
  mounted() {},
  watch: {
    focusSite: function(index) {
      if (!isNaN(index) && this.sites[index] && this.sites[index].properties) {
        this.createPopupFromIndex(index);
      }
    },

    layers: function(filters) {
      this.updatefilters(filters);
      this.clearAllPopups();
    }
  },
  methods: {
    updatefilters(filters) {
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;

      let filteredStackedData = this.map.getSource("places")._data;

      filteredStackedData.features.forEach(row => {
        row.properties.hideForStacking = false;
        row.properties.hasStacking = false;
        row.properties.stackIndex = false;
      });
      let stackIndexLookup = {}; //OBJECT of arrays to find makers that overlap.
      let stackIndexCats = {}; //OBJECT of arrays to find makers that overlap.
      filteredStackedData.features.forEach(row => {
        if (filters.includes(row.properties.Category)) {
          if (!stackIndexLookup[row.properties.geoIndex]) {
            stackIndexLookup[row.properties.geoIndex] = [];
            stackIndexCats[row.properties.geoIndex] = {};
          }
          stackIndexLookup[row.properties.geoIndex].push(
            row.properties.Site_Marker_Number
          );
          stackIndexCats[row.properties.Category.charAt(0)] = true;
        }
      });

      const markerOffset = 0.1;
      filteredStackedData.features.forEach(row => {
        if (filters.includes(row.properties.Category)) {
          if (stackIndexLookup[row.properties.geoIndex].length > 1) {
            //we have stacking after filter.
            row.properties.hideForStacking = true;
            row.properties.hasStacking = true;
            row.properties.stackIndex = stackIndexLookup[
              row.properties.geoIndex
            ].indexOf(row.properties.Site_Marker_Number);
            let stackIndex = row.properties.stackIndex;
            row.properties.stackOffset =
              stackIndex === 0
                ? [0, 10 * markerOffset]
                : stackIndex === 1
                ? [-5 * markerOffset, -5 * markerOffset]
                : stackIndex === 2
                ? [5 * markerOffset, -5 * markerOffset]
                : [0, 10 * markerOffset];

            let stackedIndexLabel = stackIndexLookup[
              row.properties.geoIndex
            ].join(", ");
            row.properties.stackedIndexLabel =
              stackedIndexLabel.length > 9
                ? stackedIndexLabel.substring(0, 9) + "..."
                : stackedIndexLabel;
          }
        }
      });

      this.map.getSource("places").setData(filteredStackedData);
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;
      //filter for clustering;

      const filter = {
        places: ["==", "hasStacking", false]
      };

      _.forEach(filter, (value, layerKey) => {
        this.map.setFilter(layerKey, [
          "all",
          value,
          ["in", "Category", ...filters]
        ]);
      });
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    },
    openSidebar(index) {
      //if fullscreen mode go back to normal
      try {
        let [FullscreenControl] = this.map._controls.filter(
          value => value.constructor.name == "FullscreenControl"
        );
        if (FullscreenControl._fullscreen === true) {
          FullscreenControl._onClickFullscreen();
        }
      } catch (error) {
        //do nothing
      }

      this.$store.dispatch("navUpdateSidebarSite", index);
      this.$store.dispatch("navUpdateSidebarOpenToInfo");
    },
    updatePaddingZoomForRemotePopup(coordinates, flyTo) {
      if (window.outerHeight < 700 || this.$vuetify.breakpoint.mobile) {
        this.map.setPadding({ bottom: (window.outerHeight - 80) / 2 });
      } else {
        this.map.setPadding({ bottom: 0 });
      }

      if (flyTo) {
        let options = {
          speed: this.useCluster ? 0.5 : 1,
          center: coordinates,
          zoom: this.map.getZoom() < 18 ? 18.4 : this.map.getZoom()
        };

        this.map.flyTo(options);
      }
    },
    createPopupFromIndex(index) {
      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;
        this.updatePaddingZoomForRemotePopup(coordinates, true);

        this.createPopup(coordinates, properties);
      } catch (error) {
        //error loacing popup
      }
    },
    getAudioDetails(name, id) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isPlayingThisTrack = true;
      }

      if (
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isLoadedThisTrack = true;
      }

      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },
    goToPointByPointName(pointName) {
      let [point] = this.sites.filter(row => {
        return row.properties.Point_Name == pointName;
      });

      if (point && point.properties && point.properties.index) {
        this.loadMarkerByIdFromMenu(point.properties.index);
      }
    },

    loadMarkerByIdFromMenu(index) {
      this.clearAllPopups();
      this.$store.dispatch("navUpdateSidebarFocusList");

      try {
        let properties = this.sites[index].properties;
        let coordinates = this.sites[index].geometry.coordinates;

        this.createNormalPopup(coordinates, properties);

        //if (this.map.getZoom() < 18) {

        this.updatePaddingZoomForRemotePopup(coordinates, true);
        //}
      } catch (error) {
        //do nothing;
      }
    },
    createPopup(coordinates, properties, allowStacking) {
      this.clearAllPopups();
      this.$store.dispatch("navUpdateSidebarFocusList");
      if (
        allowStacking &&
        properties.hasStacking &&
        this.stackedMarkers[properties.geoIndex]
      ) {
        let stackMenu = this.stackedMarkers[properties.geoIndex];
        let mappedMenuItems = stackMenu.map(
          item => this.sites[item].properties
        );

        this.createStackedMenuPopup(coordinates, mappedMenuItems);
      } else {
        this.createNormalPopup(coordinates, properties);
      }
    },
    createStackedMenuPopup(coordinates, mappedMenuItems) {
      let newPopup = new window.mapboxgl.Popup({
        className: "galMenu",
        closeButton: false
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .setOffset(this.getOffest())
        .addTo(this.map);

      this.popups.push(newPopup);

      new PopupStackedMenu({
        propsData: {
          mappedMenuItems,
          loadMarkerByIdFromMenu: this.loadMarkerByIdFromMenu,
          vuetifyCopy: this.$vuetify,
          appSettings: this.appSettings
        }
      }).$mount("#vue-popup-content");
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[[]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    updatePath(properties) {
      var oldId = this.getParameterByName("id");

      let newId = properties.Site_Marker_Number;
      //let oldId = this.$route;
      if (newId && newId !== oldId) {
        var newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?id=${newId}`;
        window.history.pushState({ path: newurl }, "", newurl);

        //this.$router.push({ path: `/?id=${newId}` });
        //do nothingz
      }
    },
    getOffest() {
      let factor = 0.8;
      if (this.lastMarkerSize > 1) {
        return 50 * factor;
      } else if (this.lastMarkerSize > 0.8) {
        return 35 * factor;
      } else if (this.lastMarkerSize > 0.6) {
        return 25 * factor;
      } else if (this.lastMarkerSize > 0.4) {
        return 20 * factor;
      } else if (this.lastMarkerSize > 0.2) {
        return 10 * factor;
      }
      return 5 * factor; //imageSize * 100;
    },
    createNormalPopup(coordinates, properties) {
      let newPopup = new window.mapboxgl.Popup({
        className: "galPopup",
        //closeOnMove: true
        closeButton: false
        //,offset: [0, -20],
      })
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .setOffset(this.getOffest())
        .addTo(this.map);

      this.popups.push(newPopup);

      new PopupContent({
        propsData: {
          properties: properties,
          clearAllPopups: this.clearAllPopups,
          getNavTabState: this.getNavTabState,
          vuetifyCopy: this.$vuetify,
          browserDetectCopy: this.$browserDetect,
          openSidebar: this.openSidebar,
          setSound: this.setSound,
          getAudioDetails: this.getAudioDetails,
          goToPointByPointName: this.goToPointByPointName,
          appSettings: this.appSettings
        }
      }).$mount("#vue-popup-content");

      let _selfStore = this.$store;
      newPopup.on("close", function(/*e*/) {
        //_selfStore.commit("updateOpenPopupSite", false);
        //_selfStore.commit("updateSidebarSite", false);

        _selfStore.dispatch("navUpdateOpenPopupSite", false);
        _selfStore.dispatch("navUpdateSidebarSite", false);

        // do what you need to do!
      });
      setTimeout(() => {
        this.updatePath(properties);
        this.$store.dispatch("navUpdateOpenPopupSite", properties.index);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      }, 30);

      window.gtag("event", "popupOpen_" + properties.index);
    },
    clearAllPopups() {
      this.$store.dispatch("navUpdateOpenPopupSite", false);
      this.popups.forEach(popup => {
        popup.remove();
      });
      this.popups = [];
    },

    createTooltip(e) {
      try {
        this.clearTooltip();
        let coordinates = e.features[0].geometry.coordinates.slice();

        this.toolTipAct = new window.mapboxgl.Popup({
          className: "pointPopupTooltip",
          //closeOnMove: true
          closeButton: false,
          anchor: "top" //offset: [0, -20],
        })
          .setLngLat(coordinates)
          .setHTML(`<div>${e.features[0].properties.Point_Name}</div>`)
          .addTo(this.map);
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    },
    clearTooltip() {
      try {
        if (this.toolTipAct) {
          this.toolTipAct.remove();
          this.toolTipAct = null;
        }
      } catch (error) {
        //do nothing
        if (!error) {
          //do nothing
        }
      }
    },
    initialized(/*map*/) {},

    async loaded(map) {
      map.addSource("attribution", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        },
        attribution: "Your custom attribution"
      });

      window.clearAllPopups = this.clearAllPopups.bind(this);
      map._isVue = true;
      this.map = map;

      artworkAssetService.addArtWorkLayer(
        this.map,
        this.sites,
        this.appSettings
      );

      // disable map rotation using right click + drag
      this.map.dragRotate.disable();
      // disable map rotation using touch rotation gesture
      this.map.touchZoomRotate.disableRotation();

      //markerService.renderArtWork(this.map, this.appSettings);
      /*
      setTimeout(() => {
        //      pitch: 30, // pitch in degrees
        //      bearing: -81, // bearing in degrees
        //      zoom: 17.2,

        this.map.flyTo({
          center: [144.3395415, -38.1488939],
          pitch: 30,
          bearing: -81,
          zoom: 17.2
          //essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
      }, 1);
*/
      if (this.useCluster === true) {
        let clusterZoomLevl = 17; //this.$vuetify.breakpoint.mobile ? 17 : 18; //when to use clusters that are tight or loose

        this.map.addSource("placesForCluster", {
          ...this.markerList,
          ...{
            cluster: true,
            clusterMaxZoom: 24,
            clusterRadius: 50,
            maxzoom: 24
          }
        });

        this.map.addSource("placesForClusterZoomed", {
          ...this.markerList,
          ...{
            cluster: true,
            clusterMaxZoom: 24,
            clusterRadius: 10,
            maxzoom: 24
          }
        });

        markerService.renderMarkersClusters(this.map, {
          sourceName: "placesForCluster",
          preFix: "",
          minZoomValue: 0,
          maxZoomValue: clusterZoomLevl
        });
        markerService.renderMarkersClusters(
          this.map,
          {
            sourceName: "placesForClusterZoomed",
            preFix: "zoom_",
            minZoomValue: clusterZoomLevl - 0.001,
            maxZoomValue: 24
          },
          this.appSettings
        );
      }

      this.map.addSource("places", { ...this.markerList });

      await markerService.createActivityIcons(
        this.map,
        ["prospective_marker", "history_marker", "wayfinding_marker"],
        this.baseurllocation
      );

      let preLoadIcons = false;

      if (preLoadIcons) {
        await markerService.createIcons(
          this.map,
          this.markerList,
          this.baseurllocation
        );
      }

      if (this.useCluster !== true) {
        if (this.useCircles) {
          markerService.renderMarkersCircles(this.map, this.appSettings);
        } else {
          markerService.renderMarkersIcons(this.map, this.appSettings);
        }
      }

      let createStackedMenuPopup = this.createStackedMenuPopup;

      this.map.on("click", "places", e => {
        try {
          this.lastMarkerSize = e.features[0].layer.layout["icon-size"];
        } catch (error) {
          //do nothing;
        }
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      if (this.useCircles && !this.useCluster) {
        this.map.on("click", "places-stackedCopy", e => {
          try {
            this.lastMarkerSize = e.features[0].layer.layout["icon-size"];
          } catch (error) {
            //do nothing;
          }

          let coordinates = e.features[0].geometry.coordinates.slice();
          let properties = e.features[0].properties;
          this.createPopup(coordinates, properties, true);
          this.$store.dispatch("navUpdateSidebarSite", properties.index);
        });
      }

      this.map.on("click", "zoom_places", e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      this.map.on("click", "zoom_clusters", function(e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ["zoom_clusters"]
        });
        var clusterId = features[0].properties.cluster_id,
          point_count = features[0].properties.point_count,
          clusterSource = map.getSource("placesForClusterZoomed");

        clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
          err,
          aFeatures
        ) {
          let mappedMenuItems = aFeatures.map(item => item.properties);
          let coordinates = [e.lngLat.lng, e.lngLat.lat];
          createStackedMenuPopup(coordinates, mappedMenuItems);
        });
      });

      this.map.on("click", "clusters", function(e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"]
        });

        var clusterId = features[0].properties.cluster_id,
          point_count = features[0].properties.point_count,
          clusterSource = map.getSource("placesForCluster");

        clusterSource.getClusterLeaves(clusterId, point_count, 0, function(
          err,
          aFeatures
        ) {
          let mappedMenuItems = aFeatures.map(item => item.properties);
          let coordinates = [e.lngLat.lng, e.lngLat.lat];
          createStackedMenuPopup(coordinates, mappedMenuItems);
        });
      });

      this.map.on("click", "places", e => {
        let coordinates = e.features[0].geometry.coordinates.slice();
        let properties = e.features[0].properties;
        this.createPopup(coordinates, properties, true);
        this.$store.dispatch("navUpdateSidebarSite", properties.index);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on("mouseenter", "places", e => {
        try {
          this.createTooltip(e);
        } catch (error) {
          //do nothing
        }
        this.map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      this.map.on("mouseleave", "places", () => {
        this.clearTooltip();
        this.map.getCanvas().style.cursor = "";
      });

      setTimeout(() => {
        //this is to load points from query tring on load; ;
        let queryStringSiteNumber = this.getParameterByName("id");
        let validTargets = this.sites.filter(
          point => point.properties.Site_Marker_Number === queryStringSiteNumber
        );

        if (validTargets[0]) {
          let coordinates = validTargets[0].geometry.coordinates.slice();
          let properties = validTargets[0].properties;
          this.createPopup(coordinates, properties, false);
          this.$store.dispatch("navUpdateSidebarSite", properties.index);

          this.map.flyTo({ center: coordinates });
        }
      }, 100);
    } /* ,
    clicked(map, e) {
      //const title = e.features[0].properties.title;
    }*/
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container > div > .mapWrapperWrapper {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  max-width: 100vw !important;
  border: none;
}

#mapContainer {
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0;
}
</style>
