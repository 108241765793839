var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-0 "},[_c('div',{staticClass:"soundcloudLinks"},[(_vm.properties.audioDescription_soundCloudId && false)?_c('v-btn',{staticClass:"autoHeightButton ma-2 white--text",attrs:{"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.width},on:{"click":function($event){return _vm.setSound(
          _vm.properties.audioDescription_soundCloudId,
          _vm.properties.Point_Name
        )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.siteAudio__audioPlayIcon_01))]),_c('span',{style:({ 'xmax-width': _vm.textWidth })},[_vm._v(_vm._s(_vm.playSite_AudioTitle01))])],1):_vm._e()],1),(_vm.properties.audioDescription_soundCloudId)?_c('v-btn',{staticClass:"my-1 mx-2 white--text oneLineEclipsText",attrs:{"title":_vm.properties.audioDescription_soundCloudTitle
        ? _vm.properties.audioDescription_soundCloudTitle
        : false,"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.width},on:{"click":function($event){return _vm.setSound(
        _vm.properties.audioDescription_soundCloudId,
        _vm.properties.Point_Name
      )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.siteAudio__audioPlayIcon_01))]),_c('span',{style:({ 'max-width': _vm.textWidth })},[_vm._v(_vm._s(_vm.playSite_AudioTitle01))])],1):_vm._e(),(_vm.properties.audioDescription_soundCloudId_02)?_c('v-btn',{staticClass:"my-1 mx-2  white--text oneLineEclipsText",attrs:{"title":_vm.properties.audioDescription_soundCloudTitle_02
        ? _vm.properties.audioDescription_soundCloudTitle_02
        : false,"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.width},on:{"click":function($event){return _vm.setSound(
        _vm.properties.audioDescription_soundCloudId_02,
        _vm.properties.Point_Name
      )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.siteAudio__audioPlayIcon_02))]),_c('span',{style:({ 'max-width': _vm.textWidth })},[_vm._v(_vm._s(_vm.playSite_AudioTitle02))])],1):_vm._e(),(_vm.properties.audioDescription_soundCloudId_03)?_c('v-btn',{staticClass:"my-1 mx-2  white--text oneLineEclipsText",attrs:{"title":_vm.properties.audioDescription_soundCloudTitle_03
        ? _vm.properties.audioDescription_soundCloudTitle_03
        : false,"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.width},on:{"click":function($event){return _vm.setSound(
        _vm.properties.audioDescription_soundCloudId_03,
        _vm.properties.Point_Name
      )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.siteAudio__audioPlayIcon_03))]),_c('span',{style:({ 'max-width': _vm.textWidth })},[_vm._v(_vm._s(_vm.playSite_AudioTitle03))])],1):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && _vm.appSettings.autoOpenMoreInfo)?_c('div',{staticStyle:{"height":"3px"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }