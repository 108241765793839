import markerList from "./../../config/markers.json";
import imagedb from "./../../config/imagedb.json";

let loadDummyVideoAudio = false;

var dummyAudio = [
  "312474809",
  "356253527",
  "55755118",
  "606739614",
  "608310531",
  "609790428",
  "614057313",
  "619503774",
  "622650333",
  "633381999"
];

dummyAudio = ["987813595"];

var dummyVideo = [
  "https://www.youtube.com/watch?v=4EIkH9EEv78",
  "https://www.youtube.com/watch?v=YMDJA4UvXLA",
  "https://www.youtube.com/watch?v=LC_nVvDKMR0",
  false,
  false
];

let multiMarkerList = {};

let stackedMarkers = {};

for (let index = 0; index < markerList.data.features.length; index++) {
  let properties = markerList.data.features[index].properties;

  if (imagedb.outputImages[properties.Point_Number]) {
    markerList.data.features[index].properties.imageFiles =
      imagedb.outputImages[properties.Point_Number];
  }
  if (imagedb.aspectRatioReport[properties.Point_Number]) {
    let aspectRatio = imagedb.aspectRatioReport[properties.Point_Number];
    let imageType = "w";

    if (aspectRatio < 1) {
      imageType = "t";
    } else if (aspectRatio < 1.25) {
      imageType = "s";
    } else {
      imageType = "w";
    }
    markerList.data.features[index].properties.imageType = imageType;
  }

  //checking for 2 youtube links;
  if (properties.youTubeUrl && properties.youTubeUrl.includes(" ;; ")) {
    let splitYouTube = properties.youTubeUrl.split(" ;; ");
    properties.youTubeUrl = splitYouTube[0].trim();
    if (splitYouTube[1]) {
      properties.youTubeUrl_2 = splitYouTube[1].trim();
    }
    if (splitYouTube[2]) {
      properties.youTubeUrl_3 = splitYouTube[2].trim();
    }
  }

  markerList.data.features[index].properties.icon = "icon" + (index + 1);
  markerList.data.features[index].properties.index = index;
  markerList.data.features[index].properties.indexLabel = index + 1;

  markerList.data.features[index].properties.indexLabel =
    properties.Point_Number;

  markerList.data.features[index].properties.stackIndex = false;
  markerList.data.features[index].properties.hasStacking = false;
  markerList.data.features[index].properties.hideForStacking = false;

  if (loadDummyVideoAudio) {
    if (dummyVideo[index % dummyVideo.length]) {
      markerList.data.features[index].properties.youTubeUrl =
        dummyVideo[index % dummyVideo.length];
    }

    if (dummyAudio[index % dummyAudio.length]) {
      markerList.data.features[index].properties.soundCloudId =
        dummyAudio[index % dummyAudio.length];
    }
  }
  try {
    let [a, b] = markerList.data.features[index].geometry.coordinates;
    let geoIndex = "geo" + a + b;

    markerList.data.features[index].properties.geoIndex = geoIndex;
    if (!multiMarkerList[geoIndex]) {
      multiMarkerList[geoIndex] = [];
    } else {
      //item already on this point
      markerList.data.features[index].properties.hideForStacking = true;
    }

    multiMarkerList[geoIndex].push(index);
    if (multiMarkerList[geoIndex].length > 1) {
      stackedMarkers[geoIndex] = multiMarkerList[geoIndex];
    }
  } catch (error) {
    //do nothing
  }

  //make house markers invisible but clickable
  try {
    markerList.data.features[index].properties.hideMakerButMakeClickable =
      "false";
    if (
      markerList.data.features[index].properties.Point_Name.includes(
        "xxxA day in the life of a Sister"
      ) ||
      (markerList.data.features[index].properties.Point_Name.includes(
        " House"
      ) &&
        !markerList.data.features[index].properties.Point_Name.includes(
          "Baggot St Mother House"
        ))
    ) {
      markerList.data.features[index].properties.hideMakerButMakeClickable =
        "true";
    }
  } catch (error) {
    //do nothing
  }

  //make house markers invisible but clickable
  try {
    if (
      markerList.data.features[index].properties.Point_Name.includes(
        "To Ireland"
      )
    ) {
      markerList.data.features[index].properties.linkPoint = {
        ID: "7a",
        Point_Number: "H07a",
        Point_Name: "Baggot St Mother House",
        Button_Title: "Navigate to Baggot St Mother House"
      };
    }

    if (
      markerList.data.features[index].properties.Point_Name.includes(
        "St Mother House"
      ) ||
      markerList.data.features[index].properties.ID === "7a" ||
      markerList.data.features[index].properties.Point_Number === "H07a"
    ) {
      markerList.data.features[index].properties.linkPoint = {
        ID: "7",
        Point_Number: "H07",
        Point_Name: "To Ireland",
        Button_Title: "Back to SHC"
      };
    }
  } catch (error) {
    //do nothing
  }
}

for (let index = 0; index < markerList.data.features.length; index++) {
  let geoIndex = markerList.data.features[index].properties.geoIndex;

  if (stackedMarkers[geoIndex]) {
    //for manual stack, if title is longer than x, add '...'

    let stackedIndexLabel = stackedMarkers[geoIndex]
      .map(n => {
        //return n + 1;

        return markerList.data.features[n].properties.indexLabel;
      })
      .join(", ");

    stackedIndexLabel =
      stackedIndexLabel.length > 9
        ? stackedIndexLabel.substring(0, 9) + "..."
        : stackedIndexLabel;

    markerList.data.features[
      index
    ].properties.stackedIndexLabel = stackedIndexLabel;

    markerList.data.features[index].properties.hasStacking = true;
    markerList.data.features[index].properties.stackIndex = multiMarkerList[
      geoIndex
    ].indexOf(index);

    let stackIndex = markerList.data.features[index].properties.stackIndex;

    const offsetAmount = 1;
    const enableMarkerOffsetPatter = false;

    markerList.data.features[index].properties.stackOffset = [
      -1 * offsetAmount * markerList.data.features[index].properties.stackIndex,
      -1 * offsetAmount * markerList.data.features[index].properties.stackIndex
    ];

    //stacking offset stuff;
    if (enableMarkerOffsetPatter) {
      markerList.data.features[index].properties.stackOffset =
        stackIndex === 0
          ? [0, 10]
          : stackIndex === 1
          ? [-5, -5]
          : stackIndex === 2
          ? [5, -5]
          : [0, 10];
    }
  }
}

// import Vue from 'vue'
const getters = {};
const actions = {};
const state = {
  markerList: markerList,
  stackedMarkers: stackedMarkers
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations
};
