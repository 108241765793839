<template>
  <v-app class="my-app">
    <Layout />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";

export default {
  name: "App",

  components: {
    Layout
  },

  data: () => ({
    //
  })
};
</script>
<style lang="scss">
/*

@import url("https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@600&display=swap");

//$body-font-family: 'Overpass Mono';
$title-font: "Overpass Mono";

.v-application {
  //font-family: $body-font-family, monospace !important;

  .heading,
  .headingText,
  .headline,
  .title,
  .subheading,
  //.v-list-item__title,
  .v-card__title,
  .v-btn,
  .v-card__title,
  .v-card__title,
  .v-card__title,
  .v-card__title,
  .v-card__title {
    // To pin point specific classes of some components
    font-family: $title-font, monospace !important;
  }

  .siteHeadingText {
    text-transform: uppercase;
  }
*/
</style>
