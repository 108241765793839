const genZoomSetps = function(scale = 16, step = 0.1) {
  var values = [0, 0];

  //var scale = 16;
  var zoom = 14;
  //  var step = 0.1;

  do {
    values.push(zoom);
    let newValue = Math.pow(2, zoom) * (scale / Math.pow(2, 23));
    values.push(newValue);
    zoom = zoom + step;
  } while (zoom <= 23);

  return values;
};

if (genZoomSetps) {
  //do nothing;
}

const artwork = [
  {
    asset: "atrium",
    file: "mapartwork/buildings/atrium.png",
    height: 773,
    width: 848
  },
  {
    asset: "baggot_house",
    file: "mapartwork/buildings/baggot_house.png",
    height: 284,
    width: 692
  },
  {
    asset: "celies",
    file: "mapartwork/buildings/celies.png",
    height: 258,
    width: 704
  },
  {
    asset: "chapel",
    file: "mapartwork/buildings/chapel.png",
    height: 404,
    width: 407
  },
  {
    asset: "gates",
    file: "mapartwork/buildings/gates.png",
    height: 336,
    width: 592
  },
  {
    asset: "grotto",
    file: "mapartwork/buildings/grotto.png",
    height: 385,
    width: 453
  },
  {
    asset: "healthandwellness",
    file: "mapartwork/buildings/healthandwellness.png",
    height: 211,
    width: 645
  },
  {
    asset: "heritage",
    file: "mapartwork/buildings/heritage.png",
    height: 395,
    width: 246
  },
  {
    asset: "orphanage",
    file: "mapartwork/buildings/orphanage.png",
    height: 300,
    width: 672
  },
  {
    asset: "orphanage_extended",
    file: "mapartwork/buildings/orphanage_extended.png",
    height: 287,
    width: 987
  },
  {
    asset: "perfarts",
    file: "mapartwork/buildings/perfarts.png",
    height: 674,
    width: 593
  },
  {
    asset: "ClairvauxHouse",
    file: "mapartwork/houses/ClairvauxHouse.png",
    height: 200,
    width: 200
  },
  {
    asset: "LoyolaHouse",
    file: "mapartwork/houses/LoyolaHouse.png",
    height: 200,
    width: 200
  },
  {
    asset: "PaduaHouse",
    file: "mapartwork/houses/PaduaHouse.png",
    height: 200,
    width: 200
  },
  {
    asset: "SienaHouse",
    file: "mapartwork/houses/SienaHouse.png",
    height: 200,
    width: 200
  },
  {
    asset: "bus",
    file: "mapartwork/other/bus.png",
    height: 2048,
    width: 2048
  },
  {
    asset: "laptop",
    file: "mapartwork/other/laptop.png",
    height: 212,
    width: 260
  },
  {
    asset: "science",
    file: "mapartwork/other/science.png",
    height: 133,
    width: 153
  },
  {
    asset: "bike2",
    file: "mapartwork/people/bike2.png",
    height: 393,
    width: 384
  },
  {
    asset: "bike_family",
    file: "mapartwork/people/bike_family.png",
    height: 129,
    width: 470
  },
  {
    asset: "footygirls",
    file: "mapartwork/people/footygirls.png",
    height: 206,
    width: 273
  },
  {
    asset: "girl01",
    file: "mapartwork/people/girl01.png",
    height: 368,
    width: 200
  },
  {
    asset: "girl03",
    file: "mapartwork/people/girl03.png",
    height: 398,
    width: 120
  },
  {
    asset: "girl04",
    file: "mapartwork/people/girl04.png",
    height: 416,
    width: 201
  },
  {
    asset: "girlgroup01",
    file: "mapartwork/people/girlgroup01.png",
    height: 227,
    width: 362
  },
  {
    asset: "girlpair",
    file: "mapartwork/people/girlpair.png",
    height: 485,
    width: 397
  },
  {
    asset: "nun04",
    file: "mapartwork/people/nun04.png",
    height: 379,
    width: 233
  },
  {
    asset: "teacher01",
    file: "mapartwork/people/teacher01.png",
    height: 986,
    width: 595
  },
  {
    asset: "teacher02",
    file: "mapartwork/people/teacher02.png",
    height: 1200,
    width: 371
  },
  {
    asset: "teacher03",
    file: "mapartwork/people/teacher03.png",
    height: 1139,
    width: 507
  },
  {
    asset: "bus04",
    file: "mapartwork/placeHolder/bus04.png",
    height: 200,
    width: 510
  },
  {
    asset: "car03",
    file: "mapartwork/placeHolder/car03.png",
    height: 157,
    width: 300
  },
  {
    asset: "Ireland",
    file: "mapartwork/placeHolder/Ireland.png",
    height: 278,
    width: 400
  },
  {
    asset: "stainglass",
    file: "mapartwork/placeHolder/stainglass.png",
    height: 244,
    width: 253
  },
  {
    asset: "tree02",
    file: "mapartwork/plants/tree02.png",
    height: 128,
    width: 75
  },
  {
    asset: "autumn_tree",
    file: "mapartwork/plants/autumn_tree.png",
    height: 166,
    width: 135
  },
  {
    asset: "tree01",
    file: "mapartwork/plants/tree01.png",
    height: 128,
    width: 75
  }
];

let zome1Factor = 1.3;
const scaleFactors = {
  //zoom1
  chapel: 0.6 * zome1Factor,
  gates: 0.4 * zome1Factor,
  orphanage_extended: 0.7 * zome1Factor,

  healthandwellness: 0.75 * zome1Factor,

  //zoom2

  atrium: 0.9,
  celies: 0.7,
  baggot_house: 0.8,
  heritage: 0.5,
  grotto: 0.4,
  perfarts: 0.7,
  car03: 0.6,
  bus04: 1,

  //zoom3
  bike2: 0.3,
  bike_family: 1,
  footygirls: 0.6,
  girl01: 0.6,
  girl03: 0.3,
  girl04: 0.3,
  girlpair: 0.4,
  nun04: 0.37,
  girlgroup01: 0.4,
  teacher01: 0.32,
  teacher02: 0.32,
  teacher03: 0.32,

  //details
  science: 0.8,
  laptop: 0.3,
  ClairvauxHouse: 0.6,
  LoyolaHouse: 0.6,
  PaduaHouse: 0.6,
  SienaHouse: 0.6,
  stainglass: 0.5,
  Ireland: 0.5,

  autumn_tree: 1.2,
  tree01: 1.6,
  tree02: 1,

  last: 0.3
};

let geoJson = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    name: "School of Lost Arts Data",
    features: [
      {
        type: "Feature",
        properties: {
          layer: "zoom1",
          name: "healthandwellness"
        },
        geometry: {
          coordinates: [144.338385, -38.1478 - 0.0001],
          type: "Point"
        },
        id: "faefb9cbff429b3256ceb5f26816619d"
      },

      {
        type: "Feature",
        properties: {
          name: "orphanage_extended",
          layer: "zoom1"
        },
        geometry: {
          coordinates: [144.339503 - 0.0001, -38.149566 + 0.0001],
          type: "Point",
          layer: "zoom1"
        },
        id: "0f3eb8358e0782d98c21251e734cc6a8"
      },
      {
        type: "Feature",
        properties: {
          name: "chapel",
          layer: "zoom1"
        },
        geometry: {
          coordinates: [144.339544 - 0.0001, -38.14889 + 0.00005],
          type: "Point"
        },
        id: "8fad2526d1cd2c88736227b99081b6c6"
      },
      {
        type: "Feature",
        properties: {
          name: "gates",
          layer: "zoom1"
        },
        geometry: {
          coordinates: [144.340132, -38.149527],
          type: "Point"
        },
        id: "95feb395bff107261f0464cbd33961e1"
      },
      {
        type: "Feature",
        properties: {
          name: "baggot_house",
          layer: "zoom1"
        },
        geometry: {
          coordinates: [-6.2464876, 53.335371],
          type: "Point"
        },
        id: "95feb395bff107261f0464cbd33961e1"
      },
      //zoom2
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "bus04"
        },
        geometry: {
          coordinates: [144.34046, -38.151008],
          type: "Point"
        },
        id: "058ce322ffaef1e746c28dba3e4c1f73"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "bus04"
        },
        geometry: {
          coordinates: [144.340609, -38.150178],
          type: "Point"
        },
        id: "3bd9a028abdf86e3f331e9334403fc99"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "perfarts"
        },
        geometry: {
          coordinates: [144.33935, -38.150182 + 0.0001],
          type: "Point"
        },
        id: "6e3e9a3e3c0b54cc9bf3ea46fb347432"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "car03"
        },
        geometry: {
          //coordinates: [144.337806, -38.148531],
          coordinates: [144.337806 + 0.00015, -38.148531 - 0.00015],
          type: "Point"
        },
        id: "91b5427be3b872b0e34a2e0b5ed0c10d"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "heritage"
        },
        geometry: {
          coordinates: [144.339744, -38.149249],
          type: "Point"
        },
        id: "95c04fc1ab0fd5ef517708a8c1d15f03"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "car03"
        },
        geometry: {
          coordinates: [144.337768 + 0.00015, -38.148814 - 0.00015],
          type: "Point"
        },
        id: "a4d431ac56d7a53a4080710963bef561"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "baggothouse"
        },
        geometry: {
          coordinates: [-6.246512, 53.33537],
          type: "Point"
        },
        id: "bbd0a78a00f446bd7776587e28d52505"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "grotto"
        },
        geometry: {
          coordinates: [144.339624, -38.148387],
          type: "Point"
        },
        id: "c615306cb571869812c72e22f0cd5095"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "celies"
        },
        geometry: {
          coordinates: [144.339785, -38.150078],
          type: "Point"
        },
        id: "d7f49a1709ca0b01876f8cf34f2335f6"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "bike_family"
        },
        geometry: {
          coordinates: [144.336866, -38.147695],
          type: "Point"
        },
        id: "e1818ae7ac636bfbbac96ab0154073f5"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "atrium"
        },
        geometry: {
          coordinates: [144.33855, -38.149835],
          type: "Point"
        },
        id: "e27b12c958360ec14b95eb60be91b4c7"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom2",
          name: "car03"
        },
        geometry: {
          coordinates: [144.337855 + 0.00015, -38.148238 - 0.00015],
          type: "Point"
        },
        id: "e297b0a55fbe443528db6331760c4015"
      },

      //zoom3

      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "girlpair"
        },
        geometry: {
          coordinates: [144.339307, -38.14845],
          type: "Point"
        },
        id: "47064128eb3de2fe37d8d2eff6b51f9a"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "girl04"
        },
        geometry: {
          coordinates: [144.339085 + 0.00002, -38.148806 + 0.00008],
          type: "Point"
        },
        id: "586fcd149b9d8342cb60d8d1c9eda3fa"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "footygirls"
        },
        geometry: {
          coordinates: [144.34, -38.148],
          type: "Point"
        },
        id: "62fadf2ca78662b73f750115e7297b8e"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "girlgroup01"
        },
        geometry: {
          coordinates: [144.340266, -38.149819],
          type: "Point"
        },
        id: "8549342cebadf9c1ec791c5c8f374153"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "nun04"
          //moveToMarker: "A day in the life of a Sister | Our Stories"
        },
        geometry: {
          //coordinates: [144.33993, -38.1489],
          coordinates: [0, 0],
          type: "Point"
        },
        id: "8badbd90a13186fb42f9b16068ee9fdf"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "girl03"
        },
        geometry: {
          coordinates: [144.3386, -38.1492],
          type: "Point"
        },
        id: "f3f54ae955501247f8e714eebc7e2639"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "bike2"
        },
        geometry: {
          coordinates: [144.341033, -38.149633],
          type: "Point"
        },
        id: "f84a54ef0092ae07750dd2279b11d810"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "teacher02"
        },
        geometry: {
          coordinates: [144.338385 + 0.00031, -38.1478],
          type: "Point"
        },
        id: "10f3771250db0a022455ff0027834fa8"
      },
      {
        type: "Feature",
        properties: {
          layer: "zoom3",
          name: "teacher03"
        },
        geometry: {
          coordinates: [144.337945 + 0.0001, -38.149924 + 0.0003],
          type: "Point"
        },
        id: "f84a54ef0092ae07750dd2279b11d810"
      },
      /*
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "teacher01"
        },
        geometry: {
          coordinates: [144.339176, -38.149721],
          type: "Point"
        },
        id: "10f3771250db0a022455ff0027834fa8"
      },*/
      //details
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "PaduaHouse",
          moveToMarker: "Padua House"
        },
        geometry: {
          coordinates: [144, -38],
          type: "Point"
        },
        id: "04a1cb177fa7e9fd0547d3eb110cee74"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "LoyolaHouse",
          moveToMarker: "Loyola House"
        },
        geometry: {
          coordinates: [144.339484, -38.15014],
          type: "Point"
        },
        id: "05144f1df8fdffb1bb14a6d675f80b96"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "SienaHouse",
          moveToMarker: "Siena House"
        },
        geometry: {
          coordinates: [144.340273, -38.150188],
          type: "Point"
        },
        id: "0e136101e23171b022df847e9418901c"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "stainglass"
        },
        geometry: {
          coordinates: [144.337694, -38.149912],
          type: "Point"
        },
        id: "72f3412c8bcc3293fafa00040070f920"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "Ireland"
          //moveToMarker: "To Ireland | Our Stories"
        },
        geometry: {
          //coordinates: [144.340185, -38.148592],
          coordinates: [0, 0],
          type: "Point"
        },
        id: "7b646c4e3a96ca88dbab21af7eb734a2"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "science"
        },
        geometry: {
          coordinates: [144.337945, -38.149924],
          type: "Point"
        },
        id: "8259cb92048daf33bd0f3a97a3dab4e0"
      },
      {
        type: "Feature",
        properties: {
          layer: "details",
          name: "ClairvauxHouse",
          moveToMarker: "Clairvaux House"
        },
        geometry: {
          coordinates: [144.338154, -38.147961],
          type: "Point"
        },
        id: "e905f737ca7c1147d628de8419ac8b72"
      },
      //trees

      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "autumn_tree"
        },
        geometry: {
          coordinates: [144.337859, -38.148761],
          type: "Point"
        },
        id: "1bc724c07195b7a3b9741b1e1cb185f9"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.339385, -38.148238],
          type: "Point"
        },
        id: "2b84c27b4b4806b288609c1e0643d92b"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340543, -38.149908],
          type: "Point"
        },
        id: "2f7b7b576282561fd1edbf10b0f3e217"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "autumn_tree"
        },
        geometry: {
          coordinates: [144.337921, -38.148504],
          type: "Point"
        },
        id: "32f5e1ad323d1854d11ad5cd9055d662"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.339706, -38.148285],
          type: "Point"
        },
        id: "33fd327542a3c90ab6f8e0a16ca4802d"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340044, -38.147716],
          type: "Point"
        },
        id: "4550901b815654bc1675b2129d1e38e3"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340405, -38.148124],
          type: "Point"
        },
        id: "4ad6c8f54e0bc1567b13256dad4dffd0"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.340554, -38.150002],
          type: "Point"
        },
        id: "57b2de4a6539aa15ae499a6e7f9166e9"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340236, -38.148377],
          type: "Point"
        },
        id: "66bcaa2db6d4f79c2652fd2026897e1a"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.340191, -38.147739],
          type: "Point"
        },
        id: "754add7409a78b3b42eb5d09c11b32cd"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340585, -38.149683],
          type: "Point"
        },
        id: "877636fab74239e2ea7dbb7eaa03112f"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.340254, -38.148294],
          type: "Point"
        },
        id: "8d2cc7acff3222b9355c7d907e4fb642"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "autumn_tree"
        },
        geometry: {
          coordinates: [144.337798, -38.149035],
          type: "Point"
        },
        id: "a0c8abcf1c91b63609db5c977e42b50d"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.340021, -38.149988],
          type: "Point"
        },
        id: "bd0ca35643166e22659950691446ac99"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree02"
        },
        geometry: {
          coordinates: [144.340307, -38.148283],
          type: "Point"
        },
        id: "bfce346c8c962193c4e4b419b36b570d"
      },
      {
        type: "Feature",
        properties: {
          layer: "trees",
          name: "tree01"
        },
        geometry: {
          coordinates: [144.340374, -38.147811],
          type: "Point"
        },
        id: "ed37026d0bb61b8b01c6f0b601e4bacd"
      }
    ]
  }
};

/*
const getZoomScaleStops = function(factor) {
  return [
    [13, factor * 1],
    [15, factor * 2],
    [17, factor * 5],
    [18, factor * 7],
    [20, factor * 14]
  ];
};
*/
geoJson.data.features.forEach(row => {
  if (!row) {
    //do nothing
  }
  //do nothing!!
  ///row.properties.stops = {stops:getZoomScaleStops(0.2);
});

import genericMapServices from "./genericMapServices.js";

export default new (class {
  async addArtWorkLayer(map, sites, appSettings) {
    //this is to move the school house markers to the same location as thier parent pointers.
    geoJson.data.features.forEach((point, index) => {
      if (geoJson.data.features[index] && point.properties.moveToMarker) {
        let foundMarkers = sites.filter(row => {
          return (
            row.properties &&
            row.properties.Point_Name === point.properties.moveToMarker
          );
        });

        if (foundMarkers.length) {
          geoJson.data.features[index].geometry = foundMarkers[0].geometry;
        }
      }

      //point.geometry.coordinates =
    });

    genericMapServices.loadImageAssets(map, artwork, scaleFactors, geoJson);

    map.addSource("artwork", geoJson);

    map.addLayer({
      minzoom: appSettings.minZoom.secondary,
      id: "artwork_zoom2",
      type: "symbol",
      source: "artwork", // reference the data source
      filter: ["==", "layer", "zoom2"],
      layout: {
        //"text-field": ["get", "name"],

        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true,

        "icon-image": ["concat", "artwork_", ["get", "name"]], // reference the image
        //"icon-size": 0.25,
        //"icon-rotate": 180,

        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0,
          15,
          0.05,
          16,
          0.1,
          17,
          0.2,
          18,
          0.4,
          19,
          0.8,
          20,
          1.6,
          21,
          3.2,
          22,
          6
        ]
      }
    });

    map.addLayer({
      minzoom: appSettings.minZoom.secondary,
      id: "artwork_zoom3",
      type: "symbol",
      source: "artwork", // reference the data source
      filter: ["==", "layer", "zoom3"],
      layout: {
        //"text-field": ["get", "name"],

        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true,

        "icon-image": ["concat", "artwork_", ["get", "name"]], // reference the image
        //"icon-size": 0.25,
        //"icon-rotate": 180,

        "icon-offset": [0, -40],
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0,
          17,
          0.22,
          20,
          1.6
        ]
      }
    });

    map.addLayer({
      minzoom: appSettings.minZoom.secondary,
      id: "artwork_details",
      type: "symbol",
      source: "artwork", // reference the data source
      filter: ["==", "layer", "details"],
      layout: {
        //"text-field": ["get", "name"],

        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true,

        "icon-image": ["concat", "artwork_", ["get", "name"]], // reference the image
        //"icon-size": 0.25,
        //"icon-rotate": 180,

        "icon-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          0,
          0,
          16,
          0,
          16.5,
          0.1,
          17,
          0.1,
          18,
          0.6,
          22,
          1
        ]
      }
    });

    map.addLayer({
      minzoom: appSettings.minZoom.secondary,
      id: "artwork_trees",
      type: "symbol",
      source: "artwork", // reference the data source
      filter: ["==", "layer", "trees"],
      layout: {
        //"text-field": ["get", "name"],

        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true,

        "icon-image": ["concat", "artwork_", ["get", "name"]], // reference the image
        //"icon-size": 0.25,
        //"icon-rotate": 180,

        //"icon-translate": [0, 0],
        "icon-offset": [0, -90],
        "icon-size": [
          "interpolate",
          ["exponential", 1],
          ["zoom"],
          0,
          0,
          16,
          0,
          16.5,
          0.1,

          18,
          0.4,
          22,
          1
        ]
      }
    });

    map.addLayer({
      minzoom: appSettings.minZoom.main,
      id: "artwork_zoom1",
      type: "symbol",
      source: "artwork", // reference the data source
      filter: ["==", "layer", "zoom1"],
      layout: {
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "text-allow-overlap": true,
        "text-ignore-placement": true,

        "icon-image": ["concat", "artwork_", ["get", "name"]], // reference the image
        //"icon-size": 0.25,
        //"icon-rotate": 180,

        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],

          0,
          0,
          15,
          0.05,
          16,
          0.1,
          17,
          0.2,
          18,
          0.4,
          19,
          0.8,
          20,
          1.6,
          21,
          3.2
        ]
      }
    });

    let coverUpShape = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [144.33878123760223, -38.15143664896667],
                  [144.33930158615112, -38.15143664896667],
                  [144.33930158615112, -38.15050857852503],
                  [144.33878123760223, -38.15050857852503],
                  [144.33878123760223, -38.15143664896667]
                ]
              ]
            }
          },
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [144.3373650312424, -38.15202934231978],
                  [144.33719605207446, -38.15227612069431],
                  [144.33724433183673, -38.1525777375737],
                  [144.3376895785332, -38.15283084168478],
                  [144.338199198246, -38.1528181865001],
                  [144.33822065591818, -38.1523330694314],
                  [144.33826625347137, -38.15189435208641],
                  [144.3376895785332, -38.15184794913599],
                  [144.3373650312424, -38.15202934231978]
                ]
              ]
            }
          }
        ]
      }
    };

    map.addSource("coverUpShape", coverUpShape);

    map.addLayer({
      //minzoom: 18,
      id: "coverUpShape",
      type: "fill",
      source: "coverUpShape", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#97a68c", // blue color fill
        "fill-opacity": 1
      }
    });
  }
})();
