<template>
  <div>
    <v-navigation-drawer
      touchless
      v-model="drawer"
      app
      right
      :width="appSettings.drawWidth"
    >
      <SideBar />
    </v-navigation-drawer>

    <v-app-bar
      flat
      clipped-left
      app
      :color="appSettings.menuBarColor"
      class="secondary--text"
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <SoundCloudPlayer />

      <v-toolbar-title class="">
        {{ playingSiteName }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <v-toolbar-title
        v-if="!(hasPlayingSiteName && $vuetify.breakpoint.mobile)"
        class="secondary--text"
      >
        <div class=" hidden-sm-and-down headingText siteHeadingText">
          <span class="font-weight-light">
            Maptivate
          </span>
          <span>SHC</span>
        </div>

        <div
          class=" hidden-md-and-up headingText siteHeadingText"
          style="font-size: 0.9em;"
        >
          <div class="font-weight-light">
            Maptivate
          </div>
          <div style="margin-top:-9px">Melbourne</div>
        </div>
      </v-toolbar-title>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="secondary--text"
      ></v-app-bar-nav-icon>
      <LayersControl />
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer app :color="appSettings.menuBarColor" style="">
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer></v-spacer>
      <YouTubeModal />

      <a
        href="https://www.maptivate.com.au/feedback"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="secondary--text mr-8"
          ><font v-if="!$vuetify.breakpoint.xsOnly">Report</font> Feedback
        </span>
      </a>
      <a
        href="http://maptivate.com.au/"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="secondary--text mr-8">
          <font v-if="!$vuetify.breakpoint.xsOnly">Powered by </font>

          Maptivate
        </span>
      </a>
      <AcknowledgementModal class="mr-8" />
      <AboutModal class="mr-8" />

      <a
        v-if="!$vuetify.breakpoint.xsOnly"
        href="https://www.shcgeelong.catholic.edu.au/"
        target="_blank"
        style="text-decoration: none!important;"
      >
        <span class="secondary--text mr-8">
          SHC
        </span>
      </a>

      <ImageGalleryModal
        v-if="imageFiles && imageFiles.length"
        :width="appSettings.drawButtonWidth"
        :title="properties.Point_Name"
        :imageFiles="imageFiles"
        :animatedGifArray="animatedGifArray"
      />
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import RulesDialog from "./components/dialogs/RulesDialog";

import Map from "./components/map/Map.vue";
import SideBar from "./components/SideBar.vue";
import AboutModal from "./components/info/AboutModal.vue";
import AcknowledgementModal from "./components/info/AcknowledgementModal.vue";
import YouTubeModal from "./components/controls/YouTubeModal.vue";

import SoundCloudPlayer from "./components/controls/SoundCloudPlayer.vue";
import ImageGalleryModal from "./components/controls/ImageGalleryModal.vue";

import LayersControl from "./components/ui/LayersControl.vue";

export default {
  components: {
    Map,
    SideBar,
    SoundCloudPlayer,
    YouTubeModal,
    LayersControl,
    AboutModal,
    AcknowledgementModal,
    ImageGalleryModal
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarOpenInfo: state => state.navigation.sidebarOpenInfo,
      sidebarSite: state => state.navigation.sidebarSite,
      closeMenu: state => state.navigation.closeMenu,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    sites() {
      return this.markerList.data.features;
    },
    hasPlayingSiteName() {
      if (!this.playingSiteName || this.playingSiteName == "") {
        return false;
      } else {
        return true;
      }
    },

    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },
    imageFiles() {
      let imageFiles = null;

      if (this.properties && this.properties.imageFiles) {
        try {
          imageFiles = this.properties.imageFiles;

          imageFiles = JSON.parse(imageFiles);
        } catch (error) {
          //do nothing
        }
      }

      return imageFiles;
    },
    animatedGifArray() {
      let animatedGifArray = null;
      if (this.properties && this.properties.animatedGifArray) {
        try {
          animatedGifArray = this.properties.animatedGifArray;
          try {
            animatedGifArray = JSON.parse(animatedGifArray);
          } catch (error) {
            //do nothing
          }
        } catch (error) {
          //do nothing
        }
      }
      return animatedGifArray;
    }
  },
  data: () => ({
    appTitle: "Maptivate",
    drawer: null
  }),
  methods: {
    track() {
      this.$ga.page("/");
    },
    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    },
    setCommand(command) {
      this.$store.dispatch("mediaUpdateCommand", command);
    }
  },
  created() {},
  mounted() {},
  watch: {
    closeMenu: function(newValue) {
      if (newValue === true) {
        this.drawer = false;
        this.$store.dispatch("navUpdateCloseMenu", false);
      }
    },
    sidebarOpenInfo: function(index) {
      if (index === true) {
        this.drawer = true;

        setTimeout(() => {
          this.$store.dispatch("navClearSidebarOpenToInfo");
        }, 100);

        if (
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          //open menu
          this.drawer = true;
        }
      }
    }
  }
};
</script>

<style>
.mapFrame,
.mapWrapper,
#mapContainer {
  xheight: calc(100vh - 100px);
  height: calc(100vh - 100px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 100px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
  }

  footer {
    font-size: 0.7em;
    height: 34px;
  }
}
.v-card__title {
  word-break: initial;
}

body div div.v-dialog {
  margin: 24px 6px;
}

@media only screen and (max-width: 960px) {
  .v-footer .mr-8 {
    margin-right: 8px !important;
  }
}
</style>
