<template>
  <v-card elevation="6" x_shaped v-if="ready">
    <v-carousel
      eager
      v-if="showCarousel && imageFiles && imageFiles.length"
      hide-delimiters
      :show-arrows="imageFiles.length > 1"
      :height="imageSize[imageType] ? imageSize[imageType] : '120px'"
    >
      <v-carousel-item
        v-for="(item, i) in imageFiles"
        :key="i"
        :src="'./images/thumb/' + item"
      ></v-carousel-item>
    </v-carousel>

    <div class="titleBox  secondary--text">
      <v-card-title key="" v-if="properties.Point_Name"
        >{{ properties.Point_Name }}
      </v-card-title>

      <v-card-subtitle
        v-if="properties.Point_Artist || properties.Point_Address"
      >
        <font
          class="font-weight-normal secondary--text  text--lighten-1"
          v-if="properties.Point_Artist"
          >{{ properties.Point_Artist }}</font
        >
        <br v-if="properties.Point_Artist && properties.Point_Address" />
        <font
          class="font-weight-light secondary--text  text--lighten-3"
          v-if="properties.Point_Address"
          >{{ properties.Point_Address }}</font
        >
      </v-card-subtitle>
    </div>

    <div class="youTubeLinks">
      <v-btn
        depressed
        v-if="linkPoint"
        :color="appSettings.mediaButtonColor"
        width="224px"
        class="autoHeightButton ma-2 white--text"
        @click="goToPointByPointName(linkPoint.Point_Name)"
      >
        <v-icon left>mdi-map-marker</v-icon>
        <span> {{ linkPoint.Button_Title }} </span>
      </v-btn>
    </div>

    <v-btn
      v-if="showCarousel && imageFiles && imageFiles.length"
      @click="loadImageGallery()"
      depressed
      :color="appSettings.mediaButtonColor"
      width="224px"
      class="ma-2 white--text"
    >
      <v-icon left>mdi-film</v-icon>
      View Gallery
    </v-btn>

    <div
      class="audioBox"
      v-if="
        properties.audioDescription_soundCloudId_03 ||
          properties.audioDescription_soundCloudId_02 ||
          properties.audioDescription_soundCloudId ||
          appSettings.validArSites[properties.Point_Number]
      "
    >
      <AudioPlayButton
        :isPopup="true"
        :appSettings="appSettings"
        :setSound="setSound"
        :getAudioDetails="getAudioDetails"
        :properties="properties"
        width="224px"
      />
    </div>
    <v-card-actions
      v-if="$vuetify.breakpoint.smAndDown || !appSettings.autoOpenMoreInfo"
    >
      <v-btn
        :color="appSettings.dialogActionButtonColor"
        text
        @click="openSidebar(properties.index)"
        width="100%"
      >
        More Info
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        v-if="false"
        right
        :color="appSettings.dialogActionButtonColor"
        text
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";

import AudioPlayButton from "./../ui/AudioPlayButton.vue";

import { eventBus } from "../../main";

export default Vue.extend({
  components: {
    AudioPlayButton
  },
  data: () => ({
    ready: false,
    showCarousel: false,
    imageType: "w",
    imageSize: { t: "180px", s: "160px", w: "140px" },
    imageFiles: []
  }),

  props: {
    goToPointByPointName: Function,
    getAudioDetails: Function,
    setSound: Function,
    openSidebar: Function,
    clearAllPopups: Function,
    appSettings: Object,
    properties: {
      required: true,
      type: Object
    },
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    linkPoint() {
      var link = null;
      try {
        if (this.properties && this.properties.linkPoint) {
          link = this.properties.linkPoint;

          try {
            link = JSON.parse(link);
          } catch (error) {
            //do nothing;
          }
        }
      } catch (error) {
        //do nothing;
      }
      return link;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    if (this.browserDetectCopy) {
      this.$browserDetect = this.browserDetectCopy;
    }
    setTimeout(() => {
      this.ready = true;
      setTimeout(() => {
        if (
          !this.$vuetify.breakpoint.smAndDown &&
          this.appSettings.autoOpenMoreInfo
        ) {
          //not small!!!
          this.openSidebar(this.properties.index);
        }
        this.showCarousel = true;
        try {
          this.imageFiles = this.properties.imageFiles;
          this.imageFiles = JSON.parse(this.imageFiles);

          this.imageType = this.properties.imageType;
        } catch (error) {
          //do nothing
        }
      }, 0);
    }, 30);
  },
  /*
  beforeUnmount() {
  },
  unmounted() {
  },
  deactivated() {
  },
  activated() {
  },
*/
  methods: {
    loadImageGallery() {
      eventBus.$emit("loadGallery");
    }
  }
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  max-width: 80vw;
}

.titleBox {
  xmargin-top: -6px;
  xmargin-bottom: -6px;
}

@media only screen and (max-width: 650px) {
  .galPopup .mapboxgl-popup-content {
    zoom: 75%;
  }

  .titleBox {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .audioBox {
    margin-bottom: -6px;
  }
}
</style>
