<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          >Acknowledgement<font v-if="!$vuetify.breakpoint.xsOnly">
            of Country
          </font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Acknowledgement of Country
        </v-card-title>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row class="contentWrapper">
            <v-col
              cols="12"
              style="overflow:hidden;max-height:100%;margin-bottom:-50px"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              <center>
                <v-img
                  class="white--text align-end  ma-5 mb-0"
                  style="overflow:hidden;max-width:20%"
                  src="/assets/landingPages/stained_glass_bunjil.png"
                >
                </v-img>
              </center>
            </v-col>

            <v-col
              cols="6"
              style="overflow:hidden;max-height:100%"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                class="white--text align-end  ma-5"
                xheight="300px"
                src="/assets/landingPages/stained_glass_bunjil.png"
              >
              </v-img>
            </v-col>
            <v-col md="6" cols="12" xstyle="max-height: calc(100vh - 150px);">
              <v-card-text
                v-if="false"
                class="black-text text-center font-weight-medium   mb-0 pb-0"
              >
                <h2
                  class="font-weight-medium   secondary--text siteHeadingText "
                >
                  Acknowledgement of Country
                </h2>
              </v-card-text>

              <v-card-text
                class="black-text text-center font-weight-medium  mb-0 pb-0"
              >
                <v-card-text
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                >
                  God of welcome and spirit dreaming we acknowledge that we are
                  meeting on the land for which the Wadawurrung people of the
                  Kulin Nation and their ancestors have been custodians for many
                  centuries, and on which they have performed age old
                  ceremonies. May we listen to Your deep heart whisper within
                  this land, in our families and in our beings, calling us to be
                  people of love and right relationship with our planet, other
                  people and ourselves.
                  <br /><br />
                  <strong
                    >For the past 162 years the Sisters of Mercy have been
                    privileged to walk on this land. </strong
                  ><br /><br />
                  May we find strength and follow in the Mercy tradition as
                  cultures united.
                </v-card-text>
                <br />
                <v-card-text
                  v-if="$vuetify.breakpoint.mdAndDown"
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                  ><v-img
                    src="/assets/Location.jpg"
                    height="40"
                    width="40"
                    style="border-radius:4px; float:left ;;"
                    center
                  ></v-img>
                  To display your current location on the map please press the
                  “locate” button positioned in the top left corner of the map.
                </v-card-text>
              </v-card-text>

              <v-card-text>
                <p></p>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      openIntro: false,
      properties: {
        audioDescription_soundCloudId: "987771763",
        Point_Name: "Introduction"
      }
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      //sidebarSite: state => state.navigation.sidebarSite,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }

      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  created() {
    if (localStorage.hasRun === "yes") {
      localStorage.hasRun = null;
      this.openIntro = false;
    } else {
      this.openIntro = true;
      localStorage.hasRun = "yes";
    }
    this.openIntro = true;

    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  mounted() {
    setTimeout(() => {
      //this.openIntro = true;
    }, 300);
  }
};
</script>

<style></style>
