<template>
  <div class="text-center ImageGallery">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      class="imageGalleryDialog"
      :width="$vuetify.breakpoint.mobile ? '95%' : '80%'"
    >
      <v-card class="">
        <v-toolbar dark :color="appSettings.mediaButtonColor">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="streetViewDialog" ref="box" style="background: black;">
          <v-carousel
            class="fullHeightMax"
            v-if="imageFilesJoined && imageFilesJoined.length"
            hide-delimiters
            :show-arrows="imageFilesJoined.length > 1"
            height="auto"
            style=""
          >
            <v-carousel-item
              class="fullHeight"
              v-for="(item, i) in imageFilesJoined"
              :key="i"
            >
              <div class="fullHeight">
                <div class="fullHeight">
                  <center
                    style="position: relative;top: 50%;transform: translateY(-50%);"
                  >
                    <img
                      :src="'./images/' + item.type + '/' + item.img"
                      key=""
                      class="fullHeightMax"
                      style="
              
                  display: block;
                  max-width: 100%;
                  width: auto;
                  height: auto;"
                    />
                  </center>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>

        <div v-for="(item, i) in imageFiles" :key="'x_' + i">
          <img
            :src="'./images/large/' + item"
            key=""
            class="fullHeightMax"
            style="
              
                  display: block;
                  
                  width: 1px;
                  height: 0;"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  props: {
    title: String,
    width: String,
    imageFiles: Array,
    animatedGifArray: Array
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    }),
    imageFilesJoined() {
      let imageFilesJoined = [];
      if (this.imageFiles && this.imageFiles.length) {
        this.imageFiles.forEach(row => {
          imageFilesJoined.push({ img: row, type: "large" });
        });
      }
      if (this.animatedGifArray && this.animatedGifArray.length) {
        this.animatedGifArray.forEach(row => {
          imageFilesJoined.push({ img: row, type: "animatedGifs" });
        });
      }

      return imageFilesJoined;
    }
  },
  created() {
    eventBus.$on("loadGallery", () => {
      this.dialog = true;
    });
  }
};
</script>

<style>
.fullScreenDialog {
  background: black !important;
}

.fullHeight {
  width: 100%;
  height: calc(100vh - 300px);
}

.fullHeightMax {
  max-height: calc(100vh - 300px);
}

.contentWrapperImage {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

body div div.v-dialog.v-dialog--fullscreen {
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .fullHeight {
    width: 100%;
    height: calc(100vh - 250px);
  }

  .fullHeightMax {
    max-height: calc(100vh - 250px);
  }

  .contentWrapperImage {
    max-height: calc(100vh - 250px);
  }
}
</style>
