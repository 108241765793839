//import { _ } from "core-js";

const getters = {};
const actions = {
  controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
  }
};

const mutations = {
  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  }
};

const state = {
  selectable: {},
  selected: {
    bools: { "Prospective Families": true, Wayfinding: true, History: true }
  },

  defaultLayers: ["History", "Prospective Families", "Wayfinding"],
  layers: ["History", "Prospective Families", "Wayfinding"],
  setup: {
    bools: [
      {
        key: "History",
        title: "Our Stories",
        color: "#582110"
      },
      {
        key: "Prospective Families",
        title: "Our School",
        color: "#961a1d"
      },
      {
        key: "Wayfinding",
        title: "Our Spaces",
        color: "#f4afcf"
      }
    ]
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
