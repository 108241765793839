<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="secondary--text  "> About </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Welcome to Maptivate Sacred Heart College Geelong
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-spacer></v-spacer>

            <v-col md="8" cols="12" class="text-center justify-center">
              <v-card-text
                v-if="false"
                class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
              >
                Welcome/About popup
              </v-card-text>
            </v-col>
            <v-spacer></v-spacer>

            <v-col md="6" cols="12">
              <center>
                <v-img
                  class="ma-5 mb-0 center"
                  v-show="$vuetify.breakpoint.smAndDown"
                  style="overflow:hidden;max-width :421px;max-height: 110px;"
                  :src="`/assets/landingPages/shc-logo-blue_mob.png`"
                ></v-img>

                <v-img
                  v-show="!$vuetify.breakpoint.smAndDown"
                  class="ma-5 center"
                  style="overflow:hidden;max-width:70%"
                  :src="`/assets/landingPages/shc-logo-blue.png`"
                ></v-img>

                <v-img
                  right
                  class="ma-5"
                  v-show="!$vuetify.breakpoint.smAndDown"
                  style=";max-width:80%"
                  :src="
                    `/assets/landingPages/thumbnail_Stained-glass_full_cropped.png`
                  "
                ></v-img>
              </center>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text
                class="black-text text-center font-weight-medium  mb-0 pb-0"
              >
                <v-card-text
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                >
                  <p>
                    Welcome to Sacred Heart College Geelong's exciting new
                    interactive digital map.This map will allow you to discover
                    the school's history and access information simply by using
                    the marked map points. Use this wonderful resource as a
                    virtual tour with information, videos, and links both within
                    the map and on the side bar.
                  </p>
                </v-card-text>
              </v-card-text>

              <v-card-subtitle
                class=" secondary--text  text-center font-weight-medium "
              >
                There are three themes to explore which can be viewed together
                or individually.
              </v-card-subtitle>
              <center>
                <v-list two-line class="ma-0 pa-0 mr-2" width="200px">
                  <template
                    v-for="(category, catKey) in appSettings.categoryLookup"
                  >
                    <v-list-item v-bind:key="catKey">
                      <v-list-item-avatar v-if="false">
                        <v-img
                          v-if="true"
                          :src="`/icons/${category.full}.png`"
                        ></v-img>
                      </v-list-item-avatar>

                      <img
                        v-if="true"
                        height="50px"
                        class="px-2"
                        :src="`/icons/${category.logo}.png`"
                      />

                      <v-list-item-content class="secondary--text ">
                        <v-list-item-title>{{
                          category.titleLong
                        }}</v-list-item-title>

                        <v-list-item-subtitle
                          class="secondary--text font-weight-light text--lighten-1"
                          style="-webkit-line-clamp: 10;"
                          >{{ category.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </center>
              <v-card-subtitle
                class=" secondary--text  text-center font-weight-medium "
              >
                We hope you enjoy Maptivate Sacred Heart College!
              </v-card-subtitle>

              <v-card-text
                v-if="$vuetify.breakpoint.mdAndDown"
                class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                ><v-img
                  src="/assets/Location.jpg"
                  height="40"
                  width="40"
                  style="border-radius:4px; float:left ;;"
                  center
                ></v-img>
                To display your current location on the map please press the
                “locate” button positioned in the top left corner of the map.
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn
              v-if="false"
              :color="appSettings.dialogActionButtonColor"
              text
            >
              More Information
              <font v-if="!$vuetify.breakpoint.smAndDown">About Maptivate</font>
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: false,
      links: [
        {
          url: "https://www.theschooloflostarts.com.au/our-story",
          title: "The School of Lost Arts"
        },
        {
          url: "https://livesmartlab.deakin.edu.au",
          title: `Deakin University, <br>
          Live + Smart Lab and IT Dept. Launch Pad`
        },

        {
          url: "https://tract.com.au/who-we-are/",
          title: "Tract Consultants Geelong"
        },

        {
          url: "https://www.onemap.com.au/",
          title: "OneMap"
        },

        {
          url: "https://www.cartdeco.com.au/about",
          title: "CartDeco Cartographics, Geelong"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  mounted() {
    setTimeout(() => {
      this.dialog = true;
    }, 150);
  }
};
</script>

<style>
.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }
}
</style>
