import Vue from "vue";
import App from "./App.vue";
//import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import VueYouTubeEmbed from "vue-youtube-embed";
import browserDetect from "vue-browser-detect-plugin";

//Logging
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

//import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

Vue.use(VueYouTubeEmbed);
Vue.use(browserDetect);

Vue.use(VueScrollTo, {
  //container: ".tabcontent",
  container: ".siteListWrapper",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Sentry.init({
  Vue,
  dsn:
    "https://6333efb4289a42afbf12426d27a63e3b@o522405.ingest.sentry.io/5633819",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  logErrors: true
});
/*
Vue.use(VueAnalytics, {
  id: "G-FDS1WQ692S"
});
*/
export const eventBus = new Vue();

new Vue({
  //router,
  store,
  vuetify,

  render: h => h(App)
}).$mount("#app");
